:root {
  --color-black: #000;
  --color-white: #fff;

  --color-gray-light: rgba(29, 28, 29, 0.05);
  --color-gray-alpha: rgba(0, 0, 0, 0.5);
  --color-gray-alpha90: rgba(0, 0, 0, 0.9);
  --color-gray: #666666;

  --color-blue: rgb(22, 89, 125);
  --color-blue-dark: #0f374d;

  --color-economic: #fda100;
  --color-social: #19a7d7;
  --color-ecolo: #2d9613;

  --color-red: rgb(189, 8, 8);
  --color-yellow: rgb(201, 184, 31);
  --color-green: rgb(17, 116, 39);
}

body {
  background-color: #fafafa;
}

input[type="text"] {
  outline: 0px;
  border: 0;
  margin: 0;
  font-size: 15px;
  width: 100%;
  -webkit-appearance: none;
  background-color: transparent;
}

textarea {
  outline: 0px;
  border: 0;
  margin: 0;
  font-size: 15px;
  width: 100%;
  -webkit-appearance: none;
  background-color: transparent;
  resize: none;
}
